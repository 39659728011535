import { Model } from "@vuex-orm/core";

import BookableResource from "./BookableResource";
import ProjectTask from "./ProjectTask";
import MsdynProject from "./MsdynProject";
import moment from "moment";

export default class TimeEntry extends Model {
  static entity = "timeEntries";
  static url = "/timeentry";
  static friendlyName = "Time entry";

  static state() {
    return {};
  }

  static fields() {
    return {
      id: this.attr(null),
      msdynDate: this.attr(null),
      msdynDuration: this.attr(null),
      msdynDescription: this.attr(null),
      vicProjectNumber: this.attr(null),
      msdynEntryStatus: this.attr(null),
      bookableResourceId: this.attr(null),
      projectTaskId: this.attr(null),
      msdynProjectId: this.attr(null),
      bookableResource: this.belongsTo(BookableResource, "bookableResourceId"),
      projectTask: this.belongsTo(ProjectTask, "projectTaskId"),
      msdynProject: this.belongsTo(MsdynProject, "msdynProjectId"),
    };

    //
  }

  get weekOfYear() {
    return moment.utc(this.msdynDate).week();
  }
  get monthOfYear() {
    return moment.utc(this.msdynDate).week();
  }
}
