<template>
  <v-app>
    <v-app-bar v-if="user" app clipped-left color="primary" dense dark>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title> Vicoma </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu bottom min-width="300px" offset-y rounded>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon x-large>
            <v-avatar color="accent" size="36">
              <img v-if="graphPhoto !== null" v-bind:src="graphPhoto" />
              <span v-else>{{ shortUserName }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card class="text-center">
          <v-list>
            <v-list-item class="d-flex justify-center mt-2">
              <v-list-item-avatar color="accent" size="120">
                <img v-if="graphPhoto !== null" v-bind:src="graphPhoto" />
                <span v-else>{{ shortUserName }}</span>
              </v-list-item-avatar>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="justify-center">
                <h3>{{ user.name }}</h3>
                <p class="caption mt-1">
                  {{ user.username }}
                </p>
                <p class="caption mt-1">v{{ appVersion }} (build {{ buildNumber }})</p>
                <v-divider class="my-3"></v-divider>
                <v-btn color="vicblue" depressed text @click="editProfile(user.localAccountId)"
                  >Edit profile</v-btn
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="justify-center">
                <v-btn depressed text @click="fullLogout">Sign out</v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-if="user" :mini-variant.sync="drawer" app clipped expand-on-hover>
      <v-list dense nav>
        <div v-for="(item, index) in drawerItems" :key="index">
          <v-list-item v-if="!item.subItems" :to="item.to">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-else :key="item.title" :prepend-icon="item.icon" :value="false">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="subItem in item.subItems" :key="subItem.title" :to="subItem.to">
              <v-list-item-icon>
                <v-icon></v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
      <template v-if="!drawer" v-slot:append>
        <div class="pa-2" align="center">
          <v-chip class="ma-2" color="vicyellow" text-color="white" small>
            Vicoma 2024 | Built by Tim Baljon
          </v-chip>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main class="lightbackground">
      <v-container fluid>
        <router-view v-if="!!user"></router-view>
        <v-row v-else class="center">
          <v-btn color="primary" @click="doLogin"> Login </v-btn>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import auth from "./plugins/auth";
import graph from "./plugins/graph";
import User from "./models/User";

export default {
  components: {
    // DataTable
  },

  data: function () {
    return {
      name: "App",
      drawer: true,
      tab: null,
      error: null,
      accessToken: "",
      graphDetails: null,
      userDetails: null,
      graphPhoto: null,
      allDrawerItems: [
        {
          title: "People",
          icon: "mdi-view-dashboard",
          to: "/",
          category: "users",
        },
        {
          title: "Timesheet",
          icon: "mdi-timetable",
          to: "/timesheet",
          category: "users",
        },
        {
          title: "Projects",
          icon: "mdi-clipboard-list-outline",
          to: "/projects",
          category: "users",
        },
      ],
      right: null,
    };
  },
  computed: {
    drawerItems() {
      const testUsers = [
        "085d9d2d-0fa3-4984-834a-e5c53fe42268",
        "0c416c00-4012-4f94-86aa-bd34f8c9d21a",
        "14c5fa19-d46a-40e4-8fb3-42e46f63f284",
        "167d0118-726c-4270-b9da-94f558f3f4d1",
        "291a522e-0b2f-4e33-bf20-4896018e5e5f",
        "2cce10ca-2249-4af8-987e-5b147ab5a0cd",
        "3242bc34-57f1-4d9f-98b0-575f75b1e552",
        "3f268c7c-34d5-48eb-afbc-6380eb868b64",
        "431145ac-c154-458d-be24-1daec9d3effc",
        "45378854-0504-48dd-89cd-39edd2cc46fa",
        "469e30f0-7ddb-4f46-99ff-e33247eff93f",
        "49eeba02-f477-4fe5-ac4b-a62230bb7e52",
        "5075706f-0d64-4865-952c-1ecc223e10f7",
        "558d58db-41ec-4948-83ad-6088b981234b",
        "5740a494-6148-469b-89aa-03f04cc449e1",
        "6ad6bf7d-969a-4a93-b7e8-5657349be4ee",
        "7293c0ac-161d-43c1-ba36-11b564e7c084",
        "7bda7a49-4ccc-40cc-a664-7f9965363af4",
        "85cd566c-d2cc-447a-9461-4ffc5618a888",
        "9ac6fe68-1888-4775-a7ce-403ebb35a468",
        "9dc5c993-9bb1-4f87-a7e9-35f4ccf7b767",
        "a8685e2c-e1c2-4a32-b537-369f39728133",
        "b173e43c-1d66-4cbd-b28b-1cbcf2db0f6b",
        "b32142e5-5305-40c0-816a-40eb1d1dd5a5",
        "b6f0d457-2686-484a-94ea-25e3ba72f2d5",
        "b8e0d604-6cc6-4723-a202-4649b69ba146",
        "b9f64eb1-cd4f-4b0d-a26b-d555e69b70fd",
        "bf353296-61dd-45cf-af7c-4a99874cf8e3",
        "c46fefac-8c7c-46ff-a6b3-d74b63e6ceb9",
        "cf4d947b-db22-401a-8f98-7fb0f032a5ba",
        "cf8992a4-9bd5-4bfb-bb25-5ec4995321f1",
        "d16ebb8b-9ac5-40cb-a6d7-d03944fa6a2f",
        "d9d11f86-30b7-41f9-a51c-15a63c9c7f83",
        "db54f15c-9fdd-463b-9ddc-f44e2118f70b",
        "ded34652-86e6-4aaa-b4d0-f8aac64fe04b",
        "e00b9c96-3dd4-4a3c-bed8-337074aa0151",
        "ea12494b-66ef-4824-976c-2528a21d7f59",
        "ed21cfde-f319-43a5-8a54-a63dfe2ffdb6",
        "ef91473d-e696-4a0e-bcad-0c901a0bace1",
        "f236bc49-5523-4cde-a425-4a9dd7b72eb6",
        "fb57fd4a-3db8-48ca-988d-7128b19872d4",
        "fda2fd52-6b39-452e-9b0f-fa9ff355197c",
        "b173e43c-1d66-4cbd-b28b-1cbcf2db0f6b",
        "167d0118-726c-4270-b9da-94f558f3f4d1",
        "2cce10ca-2249-4af8-987e-5b147ab5a0cd",
        "15c3331d-52c1-4d3b-9575-4d7631a6da0f",
        "deca2eca-d799-4f72-84f0-23b7f3a3f99f",
        "4a352851-bc91-42f2-af11-0ef362b1808a",
        "085d9d2d-0fa3-4984-834a-e5c53fe42268",
      ];
      // const myTestUsers = ["b173e43c-1d66-4cbd-b28b-1cbcf2db0f6b", "167d0118-726c-4270-b9da-94f558f3f4d1", "2cce10ca-2249-4af8-987e-5b147ab5a0cd", "15c3331d-52c1-4d3b-9575-4d7631a6da0f", "deca2eca-d799-4f72-84f0-23b7f3a3f99f", "4a352851-bc91-42f2-af11-0ef362b1808a", "085d9d2d-0fa3-4984-834a-e5c53fe42268"]
      if (testUsers.includes(this.user.id)) {
        return this.allDrawerItems;
      } else {
        return [
          {
            title: "People",
            icon: "mdi-view-dashboard",
            to: "/",
            category: "users",
          },
        ];
      }
    },
    user: {
      get() {
        return this.$store.state.entities.users.user;
      },
      set(user) {
        User.commit((state) => {
          state.user = user;
        });
      },
    },

    appVersion() {
      return this.$store.state.entities.users.appVersion;
    },
    buildNumber() {
      return this.$store.state.entities.users.buildNumber;
    },

    shortUserName() {
      if (this.user !== undefined) {
        return this.user.username.split("@", 1)[0];
      } else {
        return "";
      }
    },
    pageTitle() {
      return this.$route.name;
    },
  },
  created() {
    // Basic setup of MSAL helper with client id, or give up
    if (process.env.VUE_APP_CLIENT_ID) {
      auth.configure(process.env.VUE_APP_CLIENT_ID, false);
      // Restore any cached or saved local user
      // console.log(auth.user());
      this.user = auth.user();
      if (this.user) {
        this.user.id = this.user.localAccountId;
        this.user.online = true;
        User.insertOrUpdate({
          data: this.user,
        });
      }
    } else {
    }
  },
  mounted() {},

  watch: {
    // If user changes, make calls to Graph API
    user: async function () {
      if (this.user) {
        this.fetchGraphDetails();
      }
    },
  },

  methods: {
    async doLogin() {
      try {
        await auth.login();
        this.updateUser();
      } catch (err) {
        // console.log(err.toString());
      }
    },
    async updateUser() {
      const user = {
        ...auth.user(),
        id: auth.user().localAccountId,
        online: true,
      };

      const result = await graph.getUserPhoto(user.id);

      if (result) {
        user.graphPhoto = result;
      }

      this.user = user;
      this.graphPhoto = result;
    },
    // Remove locally held user details, is same as logout
    shallowLogout() {
      this.user = null;
      this.graphDetails = null;
      this.userDetails = null;
      this.graphPhoto = null;
      auth.clearLocal();
    },
    // Full logout local & server side
    fullLogout() {
      auth.logout();
    },
    // Get an access token and call graphGetSelf & graphGetPhoto
    async fetchGraphDetails() {
      if (!this.user || this.user.username == "demo@example.net") {
        return;
      }
      try {
        this.user.graphDetails = await graph.getSelf();
        const photo = await graph.getPhoto();
        this.user.graphPhoto = photo;
        this.accessToken = graph.getAccessToken();
        this.graphPhoto = photo;
      } catch (err) {
        // console.log(err.toString());
      }
    },

    editProfile(userId) {
      window.open(`https://eur.delve.office.com/?u=${userId}&v=editprofile`, "_blank").focus();
    },
  },
};
</script>

<style lang="scss">
$body-font-family: "Roboto";
$title-font: "Roboto";

.v-application {
  font-family: $body-font-family, sans-serif !important;

  .title {
    font-family: $title-font, sans-serif !important;
  }
}
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
