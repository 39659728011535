import auth from "./auth";
import User from "../models/User";
import axios from "axios";

const GRAPH_BASE = "https://graph.microsoft.com/beta";
const DYNAMICS_API_ENDPOINT = "/api/data/v9.2";
const DYNAMICS_BASE = "https://vicoma-prd.api.crm4.dynamics.com";
const GRAPH_SCOPES = ["user.read.all"];
const DATAVERSE_SCOPES = [`${DYNAMICS_BASE}/.default`];
const maxRetries = 3; // Maximum number of retry attempts

let accessToken;

export default {
  //
  // Get details of user, and return as JSON
  // https://docs.microsoft.com/en-us/graph/api/user-get?view=graph-rest-1.0&tabs=http#response-1
  //
  async getSelf() {
    let resp = await callGraph("/me");
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },

  //
  // Get user's photo and return as a blob object URL
  // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
  //
  async getPhoto() {
    let resp = await callGraph("/me/photos/240x240/$value");
    if (resp) {
      let blob = await resp.blob();
      return URL.createObjectURL(blob);
    }
  },

  //
  // Search for users
  // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
  //
  async searchUsers(searchString, max = 50) {
    let resp = await callGraph(
      `/users?$filter=startswith(displayName, '${searchString}') or startswith(userPrincipalName, '${searchString}')&$top=${max}`
    );
    if (resp) {
      let data = await resp.json();
      return data;
    }
  },
  //https://graph.microsoft.com/v1.0/users/${vicUserName}@vicoma.nl/photos/120x120/$value
  async getUserPhoto(id) {
    try {
      let resp = await callGraph(`/users/${id}/photos/240x240/$value`);
      if (resp) {
        let blob = await resp.blob();
        return URL.createObjectURL(blob);
      }
    } catch (err) {
      console.log(err);
    }
  },

  async fetchUserDetailsById(user, selectProperties) {
    try {
      const url = `/users/${user.id}`;
      const headers = { ConsistencyLevel: "eventual" };
      const params = { $select: selectProperties.join(",") };

      const resp = await this.callGraphWithRetry(url, headers, params);
      if (!resp) {
        return null;
      }
      const data = resp.json();
      return data;
    } catch (err) {
      console.error("An unexpected error occurred:", err);
      return null;
    }
  },

  async callGraphWithRetry(url, headers, params, retries = maxRetries, retryDelay = 1000) {
    try {
      let resp = await callGraph(url, headers, params);
      if (resp && resp.ok) {
        return await resp;
      } else if (resp && resp.status === 404) {
        console.warn(`Resource not found: ${url}`);
        return null;
      } else if (resp && resp.status === 429 && retries > 0) {
        // Handle throttling response
        let retryAfter = resp.headers.get("Retry-After");
        retryAfter = retryAfter ? parseInt(retryAfter) * 1000 : retryDelay;
        console.warn(
          `Throttled. Retrying after ${retryAfter} ms... (${
            maxRetries - retries + 1
          }/${maxRetries})`
        );
        await new Promise((resolve) => setTimeout(resolve, retryAfter));
        return await this.callGraphWithRetry(url, headers, params, retries - 1, retryDelay * 2); // Exponential backoff
      } else if (retries > 0) {
        console.warn(`Retrying... (${maxRetries - retries + 1}/${maxRetries})`);
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
        return await this.callGraphWithRetry(url, headers, params, retries - 1, retryDelay * 2); // Exponential backoff
      } else {
        console.error(`Failed after ${maxRetries} attempts`);
        return null;
      }
    } catch (err) {
      console.error(err);
      if (retries > 0) {
        console.warn(`Retrying... (${maxRetries - retries + 1}/${maxRetries})`);
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
        return await this.callGraphWithRetry(url, headers, params, retries - 1, retryDelay * 2); // Exponential backoff
      } else {
        console.error(`Failed after ${maxRetries} attempts`);
        return null;
      }
    }
  },

  async fetchAllUsersDetails() {
    try {
      let resp = await callGraph(
        "/users",
        { ConsistencyLevel: "eventual" },
        {
          $select:
            "id, displayName, mail, userPrincipalName, givenName, surname, officeLocation, jobTitle, mobilePhone, department, businessPhones, faxNumber",
          $top: 500,
          $count: true,
          $filter:
            "jobTitle ne null and accountEnabled eq true and not endsWith(mail, 'onmicrosoft.com') and givenName ne 'Ztw' and id ne 'ec243721-fe1a-4464-b4e2-5b3c76be3502' and id ne '006fb889-19d3-4c71-acc1-44117035c486' and id ne '93c1be18-78dd-45e2-9fdb-1a0f693f67c8' and id ne 'b2cdaf9b-5440-4e8d-a06b-de2d6e1b2f97' and id ne '9cf1cefb-7cd7-4602-a62f-37137540f647' and id ne '1270b7ee-b1f1-4985-81ab-01ddf5917b77' and id ne 'b03bf1f4-1385-4956-af70-f00439b4efe7' and id ne '9042ada0-9759-429d-b325-3170e0d91e22'",
          $orderby: "displayName",
        }
      );

      if (resp) {
        let data = await resp.json();
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  },

  async fetchUserDetailsByIdInbatch(users, selectProperties) {
    const batchUrl = "https://graph.microsoft.com/v1.0/$batch";
    const userIds = users.map((user) => user.id);
    const responseData = [];
    accessToken = await auth.acquireToken(GRAPH_SCOPES);
    for (let i = 0; i < userIds.length; i += 20) {
      const slicedUserIds = userIds.slice(i, i + 20);
      const batchRequest = {
        requests: slicedUserIds.map((userId) => ({
          id: userId,
          method: "GET",
          url: `/users/${userId}?$select: ${selectProperties.join(",")}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            ConsistencyLevel: "eventual",
          },
        })),
      };

      try {
        const response = await axios.get(batchUrl, batchRequest, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          responseData.push(response.data);
        } else {
          return response.data;
        }
      } catch (error) {
        throw error;
      }
    }
    if (responseData.length > 0) {
      return responseData;
    }
    return null;
  },

  async getLoggedInMsdynUser() {
    //Tim : 3a4b1538-dfaa-ec11-b3fe-000d3ab92fb0
    //_owninguser_value

    try {
      let resp = await callDynamics("/WhoAmI");

      if (resp) {
        let data = await resp.json();
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  },

  async fetchTimeEntriesByOwningUserId(msdynUser) {
    //Tim : 3a4b1538-dfaa-ec11-b3fe-000d3ab92fb0
    //_owninguser_value

    try {
      let resp = await callDynamics(
        "/msdyn_timeentries?",
        {
          "If-None-Match": null,
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`,
        },
        {
          $select:
            "msdyn_date, msdyn_duration, vic_projectnumber,_msdyn_projecttask_value,msdyn_entrystatus, msdyn_description",
          $expand:
            "msdyn_project($select=msdyn_subject,vic_projectnumber,pyl_internal),msdyn_bookableresource($select=name,pyl_contracthours),msdyn_projectTask($select=msdyn_subject,_vic_hourcodeid_value,_msdyn_parenttask_value;$expand=vic_hourcodeid($select=vic_hourcode), vic_hourgroupid($select=vic_name))",
          $filter: `_owninguser_value eq '${msdynUser}'`,
        }
      );

      if (resp) {
        let data = await resp.json();
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  },

  //
  // Accessor for access token, only included for demo purposes
  //
  getAccessToken() {
    return accessToken;
  },
};

//
// Common fetch wrapper (private)
//
async function callGraph(apiPath, customHeaders, params) {
  // Acquire an access token to call APIs (like Graph)
  // Safe to call repeatedly as MSAL caches tokens locally
  let resp;
  accessToken = await auth.acquireToken(GRAPH_SCOPES);

  try {
    resp = await fetch(
      `${GRAPH_BASE}${apiPath}${params ? "?" + new URLSearchParams(params) : ""}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ...customHeaders,
        },
      }
    );
  } catch (err) {
    console.error("Fetch error:", err);
  }
  // console.log(resp)
  return resp;
}

async function callDynamics(apiPath, customHeaders, params) {
  let resp;
  accessToken = await auth.acquireToken(DATAVERSE_SCOPES);

  try {
    resp = await fetch(
      `${DYNAMICS_BASE}${DYNAMICS_API_ENDPOINT}${apiPath}` + new URLSearchParams(params),
      {
        headers: { ...{ Authorization: `Bearer ${accessToken}` }, ...customHeaders },
      }
    );
  } catch (err) {
    console.log(err);
  }

  if (!resp.ok) {
    if (resp.status == 404) {
      // console.log("Nothing found")
      return null;
    } else {
      throw new Error(`Call to ${GRAPH_BASE}${apiPath} failed: ${resp.statusText}`);
    }
  }

  return resp;
}
