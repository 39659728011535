<template>
  <v-card height="100%" width="100%">
    <v-toolbar dense :color="selected.length > 1 ? 'warning' : 'secondary'" dark>
      <v-toolbar-title>
        {{ selected.length ? `${selected.length} selected` : `${routeName}` }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-tooltip top>
        <v-scale-transition>
          <v-btn v-if="selected.length" key="export" icon>
            <v-icon>mdi-export-variant</v-icon>
          </v-btn>
        </v-scale-transition>
        <span>Top tooltip</span>
      </v-tooltip>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-toolbar>

    <v-toolbar flat dense>
      <v-progress-linear :active="showProgressBar" indeterminate absolute top color="vicyellow"></v-progress-linear>

      <v-spacer></v-spacer>
      <v-text-field
        :disabled="searchIsDisabled"
        class="shrink mx-4"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        :color="$vuetify.theme.dark ? 'vicyellow' : ''"
      ></v-text-field>
    </v-toolbar>

    <v-skeleton-loader align="stretch" min-height="74%" v-if="timeEntries === null" type="table"></v-skeleton-loader>

    <ag-grid-vue
      style="height: calc(100vh - 26vh); width: 100%"
      v-else
      :rowData="timeEntries"
      class="ag-theme-material"
      @grid-ready="onGridReady"
      @firstDataRendered="onFirstDataRendered"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :components="components"
      :suppressRowClickSelection="false"
      :pagination="true"
      :paginationPageSize="100"
      :groupDefaultExpanded="-1"
      :animateRows="true"
      :overlayLoadingTemplate="overlayLoadingTemplate"
      :getRowId="getRowId"
      :suppressMaintainUnsortedOrder="true"
      :enableRangeSelection="true"
      :groupAllowUnbalanced="true"
      :pivotMode="true"
      pivotRowTotals="after"
      pivotColumnGroupTotals="after"
      :suppressAggFuncInHeader="true"
      :groupSuppressBlankHeader="true"
      :autoGroupColumnDef="autoGroupColumnDef"
      :groupDisplayType="groupDisplayType"
      :sideBar="sideBar"
      :groupTotalRow="groupTotalRow"
      :showOpenedGroup="false"
      :groupHideOpenParents="true"
      :statusBar="statusBar"
      :maintainColumnOrder="false"
      @row-group-opened="onRowGroupOpened"
      :enableCharts="true"
      :processPivotResultColDef="processPivotResultColDef"
      :processPivotResultColGroupDef="processPivotResultColGroupDef"
      :removePivotHeaderRowWhenSingleValueColumn="true"
      ::autoSizeStrategy="autoSizeStrategy"
    >
    </ag-grid-vue>

    <v-snackbar v-model="snack.show" :timeout="snack.timeout" :color="snack.color">
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack.show = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import graph from "../plugins/graph";
import TimeEntry from "../models/TimeEntry";
import BookableResource from "../models/BookableResource";
import CustomSorters from "../helpers/customSorters";
import moment from "moment";
import SingleSelectFilter from "../components/singleSelectFilter.vue";

export default {
  components: {
    AgGridVue,
    SingleSelectFilter,
  },
  data: () => ({
    columnDefs: null,
    gridApi: null,
    columnApi: null,
    defaultColDef: null,
    components: null,
    loading: true,
    showProgressBar: false,
    overlayLoadingTemplate: null,
    selected: [],
    snack: { show: false, color: "", text: "", timeout: 3000 },
    search: "",
    dialog: false,
    dialogDelete: false,
    dialogCopy: false,
    valid: true,
    rules: {
      required: (value) => !!value || "Required.",
      selectRequired: (value) => value.id != 0 || "Required.",
      isNumber: (value) => !isNaN(parseFloat(value)) || "Must be number > 0",
    },
    cellFlashDelay: null,
    cellFadeDelay: null,
    defaultExcelExportParams: null,
    searchIsDisabled: true,
    groupDisplayType: null,
    autoGroupColumnDef: null,
    groupTotalRow: null,
    autoSizeStrategy: null,
    pivotRowTotals: null,
    pivotColumnGroupTotals: null,
    sideBar: null,
    processPivotResultColDef: null,
    processPivotResultColGroupDef: null,
  }),

  computed: {
    timeEntries() {
      return TimeEntry.query().with(["projectTask.*"]).withAll().orderBy("msdyn_date").get();
    },
    bookableResource() {
      return BookableResource.query().first();
    },
    formTitle() {
      return `New ${this.routeName.toLowerCase().slice(0, -1)}`;
    },
    routeName() {
      return this.$route.name;
    },
    darkThemeIsActive() {
      return this.$vuetify.theme.dark;
    },
  },

  watch: {
    darkThemeIsActive(val) {
      this.gridApi.refreshCells({ force: true });
    },
    search(val) {
      this.gridApi.setQuickFilter(val);
    },
    timeEntries(val) {
      this.$nextTick(() => {
        this.pivotRowTotals = "after";
        // this.gridApi.refreshCells({ force: true });
        // this.gridApi.refreshHeader();
        // this.gridApi.redrawRows();
        this.gridApi.setGridOption("pivotMode", false);
        this.gridApi.setGridOption("pivotMode", true);
      });
    },
  },

  beforeMount() {
    this.enableRangeSelection = true;
    this.defaultExcelExportParams = {
      processCellCallback: (params) => {
        const valueFormatter = params.column.getColDef().valueFormatter;
        return !!valueFormatter ? valueFormatter(params) : params.value;
      },
    };
    this.defaultColDef = {
      resizable: true,
      //flex: 1,
      filter: true,
      sortable: true,
      // enablePivot: true,
      floatingFilter: false,
      suppressHeaderMenuButton: true,
      contextMenuItems: ["copy", "copyWithHeaders", "copyWithGroupHeaders", "export"]
    };
    this.columnDefs = [
      {
        headerName: "Year",
        hide: true,
        colId: "year",
        valueGetter: (params) => {
          if (!params.data) return;
          return moment.utc(params.data.msdynDate).year();
        },
        sort: "asc",
        filter: "SingleSelectFilter",
        filterParams: (params) => {
          return {
            values: Array.from(
              new Set(
                this.timeEntries.map((timeEntry) => {
                  return new Date(timeEntry.msdynDate).getFullYear().toString();
                })
              )
            ),
            defaultValue: new Date().getFullYear().toString(),
          };
        },
      },

      {
        headerName: "Week",
        colId: "week",
        flex: 1,
        valueGetter: (params) => {
          if (!params.data) return;
          return moment.utc(params.data.msdynDate).week();
        },
        rowGroup: true,
        sort: "asc",
      },
      {
        headerName: "Date",
        hide: true,
        lockVisible: true,
        colId: "date",
        flex: 1,
        valueGetter: (params) => {
          if (!params.data) return;
          return new Date(params.data.msdynDate);
        },
        sort: "asc",
        filter: "agSetColumnFilter",
        filterParams: {
          treeList: true,
          treeListFormatter: this.treeListFormatter,
          valueFormatter: this.dateFloatingFilterValueFormatter,
        },
      },
      {
        valueGetter: (params) => new Date(params.data?.msdynDate)?.toLocaleDateString("en-EN", { weekday: "short" }),
        comparator: (valueA, valueB) => CustomSorters.sortDayOfWeek(valueA, valueB),
        pivotComparator: (valueA, valueB) => CustomSorters.sortDayOfWeek(valueA, valueB),
        headerName: "Day of week",
        pivot: true,
        flex: 1,
        enablePivot: true,
      },
      {
        field: "vicProjectNumber",
        headerName: "Project number",
        flex: 2,
        sortable: true,
        rowGroup: true,
        enableRowGroup: true,
        sort: "asc",
      },
      {
        valueGetter: (params) =>
          params.data?.projectTask
            ? `${params.data.projectTask?.vicHourCode?.code ? params.data.projectTask?.vicHourCode?.code + " | " : ""}${
                params.data.projectTask.msdynSubject
              }`
            : null,
        headerName: "Activity",
        colId: "projectTask",
        flex: 4,
        sortable: true,
        rowGroup: true,
        sort: "asc",
        enableRowGroup: true,
      },
      {
        field: "msdynDescription",
        headerName: "Description",
        colId: "description",
        flex: 3,
        sortable: true,
        sort: "asc",
        rowGroup: true,
        enableRowGroup: true,
      },
      {
        valueGetter: (params) => {
          return params.data?.msdynDuration ? params.data.msdynDuration / 60 : null;
        },
        headerName: "Total hours",
        colId: "totalHours",
        hide: false,
        sortable: true,
        aggFunc: "sum",
        flex: 1,
        cellStyle: (params) => {
          if (params.node.level === 0 && params.column.colId === "PivotRowTotal_pivot_0__1") {
            return this.validateHours(params);
          }
          return null;
        },
      },
      {
        field: "msdynEntryStatus",
        headerName: "Status",
        hide: true,
        sortable: true,
      },
    ];

    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Please wait while data is loading</span>';
  },

  async created() {
    this.cellFlashDelay = 2000;
    this.cellFadeDelay = 500;
    this.groupDisplayType = "multipleColumns";
    this.pivotRowTotals = "after";
    this.pivotColumnGroupTotals = "after";
    this.autoGroupColumnDef = {
      flex: 2,
      cellRendererParams: {
        suppressCount: true,
      },
    };

    // this.sideBar = "filters";
    this.sideBar = {
      toolPanels: [
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          toolPanelParams: {
            suppressFilterSearch: true,
          },

          // minWidth: 180,
          // maxWidth: 250,
          // width: 200,
        },
      ],
      position: "right",
      defaultToolPanel: "filters",
    };
    this.statusBar = {
      statusPanels: [
        {
          statusPanel: "agAggregationComponent",
          statusPanelParams: {
            aggFuncs: ["sum"],
          },
        },
      ],
    };

    this.processPivotResultColDef = (colDef) => {
      if (colDef.pivotValueColumn.getId() === "totalHours") {
        // colDef.headerName = colDef.headerName.toUpperCase();
      }
    };

    this.processPivotResultColGroupDef = (colDef) => {
      // colDef.headerName = "pivot-gold"; // the params are mutated directly, not returned
    };

    const msdynUser = await graph.getLoggedInMsdynUser();
    const response = await graph.fetchTimeEntriesByOwningUserId(msdynUser.UserId);

    if (response.value) {
      const timeEntries = response.value.map((timeEntry) => {
        return {
          id: timeEntry.msdyn_timeentryid,
          msdynDate: timeEntry.msdyn_date,
          msdynDuration: timeEntry.msdyn_duration,
          msdynDescription: timeEntry.msdyn_description,
          vicProjectNumber: timeEntry.vic_projectnumber,
          msdynEntryStatus: timeEntry["msdyn_entrystatus@OData.Community.Display.V1.FormattedValue"],
          bookableResource: {
            id: timeEntry.msdyn_bookableresource.bookableresourceid,
            name: timeEntry.msdyn_bookableresource.name,
            contractHours: timeEntry.msdyn_bookableresource.pyl_contracthours,
            msdynUser: {
              id: msdynUser.UserId,
            },
          },
          projectTask: {
            id: timeEntry.msdyn_projectTask.msdyn_projecttaskid,
            msdynSubject: timeEntry.msdyn_projectTask.msdyn_subject,
            vicHourCode: timeEntry.msdyn_projectTask?.vic_hourcodeid
              ? {
                  id: timeEntry.msdyn_projectTask.vic_hourcodeid.vic_hourcodeid,
                  code: timeEntry.msdyn_projectTask.vic_hourcodeid.vic_hourcode,
                }
              : null,
            vicHourGroup: timeEntry.msdyn_projectTask?.vic_hourgroupid
              ? {
                  id: timeEntry.msdyn_projectTask.vic_hourgroupid.vic_hourgroupid,
                  name: timeEntry.msdyn_projectTask.vic_hourgroupid.vic_name,
                }
              : null,
          },
          msdynProject: {
            id: timeEntry.msdyn_project.msdyn_projectid,
            msdynSubject: timeEntry.msdyn_project.msdyn_subject,
            vicProjectNumber: timeEntry.msdyn_project.vic_projectnumber,
          },
        };
      });

      TimeEntry.insert({
        data: timeEntries,
      });
    }

    this.autoSizeStrategy = {
      type: "fitCellContents",
    };
  },

  async mounted() {},

  destroyed() {},

  methods: {
    //Helpers

    //Table related methods
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.showProgressBar = false;
      this.pivotRowTotals = "after";
      this.$nextTick(() => {
        this.pivotRowTotals = "after";
        this.gridApi.refreshCells({ force: true });
        params.api.refreshHeader();
        params.api.redrawRows();
      });
    },

    onFirstDataRendered(params) {
      this.searchIsDisabled = false;
      this.gridApi
        .setColumnFilterModel("year", {
          values: [new Date().getFullYear().toString()],
        })
        .then(() => {
          this.gridApi.onFilterChanged();
        });

      // this.gridApi.autoSizeColumns(["ag-Grid-AutoColumn-projectTask"], false);
      this.gridApi.autoSizeAllColumns();
      // this.pivotRowTotals = "after";
      // params.api.forEachNode((node) => {
      //   if (node?.rowGroupColumn?.colId === "week" && node.key == moment(new Date()).week()) {
      //   }
      // });

      // const columnToUpdate = params.api
      //   .getAllGridColumns()
      //   .find((columnDef) => columnDef.colId === "PivotRowTotal_pivot_0__1");
    },

    onRowGroupOpened(event) {
      const rowNodeIndex = event.node.rowIndex;
      // factor in child nodes so we can scroll to correct position
      const childCount = event.node.childrenAfterSort ? event.node.childrenAfterSort.length : 0;
      // const newIndex = rowNodeIndex + childCount;
      // this.gridApi.ensureIndexVisible(newIndex);
      // this.gridApi.autoSizeColumns(["ag-Grid-AutoColumn-projectTask"], false);
      // this.gridApi.autoSizeColumns(["ag-Grid-AutoColumn-vicProjectNumber"], false);
      // this.pivotRowTotals = "after";
    },

    getRowId(params) {
      return params.data.id;
    },

    validateHours(params) {
      if (params.node && params.value > this.bookableResource.contractHours) {
        return { backgroundColor: "#fff2cc" };
      } else if (params.node && params.value < this.bookableResource.contractHours) {
        return { backgroundColor: "#ffd3d3 ", fontWeight: "900" };
      } else {
        return { backgroundColor: "" };
      }
    },
    setCellStyleForValidation(isValid) {
      return isValid ? { backgroundColor: "" } : { backgroundColor: "#ffd3d3 ", fontWeight: "900" };
    },
    treeListFormatter(pathKey, level, _parentPathKeys) {
      if (level === 1) {
        const date = new Date();
        date.setMonth(Number(pathKey) - 1);
        return date.toLocaleDateString(undefined, { month: "long" });
      }
      return pathKey || "(Blanks)";
    },
    dateFloatingFilterValueFormatter(params) {
      return params.value ? params.value.toLocaleDateString() : "(Blanks)";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.ag-theme-material .ag-cell.ag-column-last {
  // font-weight: 900;
}
.ag-theme-material .ag-spacing {
  spacing: 10px;
}

.ag-theme-material .ag-header-cell {
  border-right: 1px solid #cfd8dc; /* Adjust the color and thickness as needed */
}
.ag-theme-material .ag-cell {
  border-right: 1px solid #cfd8dc; /* Adjust the color and thickness as needed */
}
.ag-theme-material .ag-header-group-cell,
.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-pivot-cols-container {
  border-right: 1px solid #cfd8dc; /* Adjust the color and thickness as needed */
  border-top: 1px solid #cfd8dc; /* Add top border */
}

.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
  color: #000000de; /* Change 'red' to any color you want */
}

.ag-theme-material .ag-row-odd {
  background-color: rgb(244, 246, 251);
}

// .ag-theme-material .ag-row.ag-row-level-0 {
//   background-color: #ccc;
// }

// .ag-theme-material .ag-row.ag-row-level-1 {
//   background-color: #eee;
// }

// .ag-theme-material-dark .ag-row.ag-row-level-0 {
//   background-color: #444;
// }

// .ag-theme-material-dark .ag-row.ag-row-level-1 {
//   background-color: #333;
// }
</style>
